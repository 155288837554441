@tailwind base;
@tailwind components;
@tailwind utilities;


html {

}

body {

    background: #ffffff;

    padding-bottom: 100px;

}

.color_primary {
    background-color: rgb(96 165 250);
    color: #ffffff;
    border: none;
}

.color_white {
    background-color: #ffffff;
    color: rgb(17 24 39);
    border: none;
}

.text_primary {
    color: rgb(96 165 250);
}

.input_primary {
    border-color: rgb(96 165 250);
}


.btn_primary {
    background-color: rgb(96 165 250);
    color: #ffffff;
}

.btn_primary:hover {
    background-color: rgb(51, 142, 253) !important;
}

.btn_white {
    background-color: #fff;
    border: none;
    color: rgb(17 24 39)
}

.btn_white:hover {
    background-color: #d5d5d5;
}

.bg_imaged {
    z-index: 1;
    position: relative;

    background: linear-gradient(
        180deg,
        rgba(96,165,250, 1) 0%,
        rgba(133, 182, 243, 0.8) 50%,
        rgba(96,165,250, 1) 100%
      ),
      url("./assets/bg_ai.jpg") 50% / cover no-repeat;
    background-blend-mode: difference;
    background-size: cover;
}